import { Suspense, useMemo, useState } from "react";
import { FormProvider } from "react-hook-form";

import {
  CurrentUserWithAccountsDocument,
  IntegrationAbility,
  IntegrationConfigurationFragment,
  useGetSlackChannelsQuery,
  useIntegrationConfigurationSuspenseQuery,
  useSetupSlackNotificationsMutation,
} from "@/apollo/types";
import { ActionButton } from "@/components/elements/Button";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Select from "@/components/elements/Select";
import {
  SlideOver,
  SlideOverBody,
  SlideOverCloseButton,
  SlideOverHeader,
} from "@/components/elements/SlideOver";
import Center from "@/components/elements/layout/Center";
import { Overlay } from "@/components/elements/layout/Overlay";
import FieldLabel from "@/components/primitives/InputLabel";
import { useConnections } from "@/features/connectors";
import { IntegrationLogoBox, useCreateConnection } from "@/integrations";
import ConnectionForm, {
  ConnectionFormSubmitButton,
  useConnectionForm,
} from "@/pages/Connections/ConnectionForm/ConnectionForm";
import { useToast } from "@/providers/ToastProvider";

type SlackSetupSlideOverProps = {} & Pick<
  React.ComponentProps<typeof SlideOver>,
  "show" | "onClose"
>;

const slackIntegrationId = "slack";

export function SlackSetupSlideOver(props: SlackSetupSlideOverProps) {
  return (
    <SlideOver show={props.show} onClose={props.onClose} bgOverlay>
      <SlideOverCloseButton />
      <SlideOverHeader>
        <div className="flex gap-3">
          <IntegrationLogoBox id={slackIntegrationId} size="md" />
          <Center className="">
            <p>Setup Slack Notifications</p>
          </Center>
        </div>
      </SlideOverHeader>
      <SlideOverBody className="p-0">
        <Suspense fallback={<LoadingSpinner />}>
          <SlackSetupContainer onComplete={() => props.onClose()} />
        </Suspense>
      </SlideOverBody>
    </SlideOver>
  );
}

type Connection = ReturnType<typeof useConnections>["connections"][0];

function SlackSetupContainer(props: { onComplete: () => void }) {
  const toast = useToast();

  const { data } = useIntegrationConfigurationSuspenseQuery({
    variables: {
      id: slackIntegrationId,
    },
  });
  const integration = data.integrationConfiguration;

  const {
    connections,
    loading: isLoadingConnections,
    refetch: refetchConnections,
  } = useConnections(IntegrationAbility.Utility);

  const slackConnections = connections.filter(
    (x) => x.integrationId === slackIntegrationId,
  );
  const hasSlackConnection = slackConnections.length > 0;

  const { loading, createConnection } = useCreateConnection({
    onSuccess() {
      refetchConnections();
    },
    onError(error) {
      toast("Connection not created", error.message, "error");
    },
  });

  const [setupNotification, { loading: isSavingChannel }] =
    useSetupSlackNotificationsMutation({
      onCompleted(data) {
        const channelName =
          data.setupSlackNotifications.slackNotificationInfo?.channelName;
        props.onComplete();
        toast(
          "Slack notifications successfully set up",
          `You will now receive notifications in the #${channelName} channel`,
          "success",
        );
      },
      onError(error) {
        toast("Slack notifications not set up", error.message, "error");
      },
      refetchQueries: [CurrentUserWithAccountsDocument],
    });

  return (
    <div className="relative flex h-full flex-col px-6">
      {isLoadingConnections && (
        <Overlay className="absolute bg-white/80">
          <Center className="h-full w-full">
            <LoadingSpinner />
          </Center>
        </Overlay>
      )}
      {hasSlackConnection ? (
        <SetupSlackChannel
          connections={connections}
          onSaveChannel={(channel, connection) => {
            setupNotification({
              variables: {
                channelId: channel.value,
                channelName: channel.label,
                connectionId: connection.value,
              },
            });
          }}
          isLoading={isSavingChannel}
        />
      ) : (
        <SetupSlackConnection
          onSubmit={(data) => {
            return createConnection(integration, data);
          }}
          integration={integration}
          isLoading={loading}
        />
      )}
    </div>
  );
}

function SetupSlackConnection(props: {
  integration: IntegrationConfigurationFragment;
  isLoading: boolean;
  onSubmit: (data: any) => void;
}) {
  const formMethods = useConnectionForm(props.integration);
  return (
    <FormProvider {...formMethods}>
      <div className="grow">
        <div className="flex flex-col gap-8">
          <form
            id="integration-form"
            onSubmit={formMethods.handleSubmit(props.onSubmit)}
            className="flex h-full flex-col"
          >
            <ConnectionForm
              integration={props.integration}
              isLoading={props.isLoading}
            />
          </form>
        </div>
      </div>
      <div className="flex shrink-0 grow-0 flex-row-reverse gap-4 py-4">
        <ConnectionFormSubmitButton
          integration={props.integration}
          isLoading={props.isLoading}
        />
      </div>
    </FormProvider>
  );
}

type DropdownOption = {
  value: string;
  label: string;
};

function SetupSlackChannel(props: {
  connections: Connection[];
  onSaveChannel: (channel: DropdownOption, connection: DropdownOption) => void;
  isLoading: boolean;
}) {
  const slackConnectionOptions = props.connections.map((x) => ({
    value: x.id,
    label: x.label,
  }));

  const [selectedConnection, setSelectedConnection] =
    useState<DropdownOption | null>(() => {
      return slackConnectionOptions[0] || null;
    });

  const [selectedChannel, setSelectedChannel] = useState<DropdownOption>();

  const { data: slackChannelsData, loading: isSlackChannelsLoading } =
    useGetSlackChannelsQuery({
      variables: {
        connectionId: selectedConnection?.value || "",
      },
      skip: !selectedConnection,
    });

  const slackChannelOptions = useMemo(() => {
    if (!slackChannelsData) {
      return [];
    }
    return slackChannelsData.getSlackChannels.map((x) => ({
      value: x.id,
      label: x.name,
    }));
  }, [slackChannelsData]);

  return (
    <>
      <div className="flex grow flex-col gap-4">
        {!selectedConnection ||
          (slackConnectionOptions.length > 1 && (
            <div>
              <FieldLabel>Connection</FieldLabel>
              <Select
                placeholder="Select connection"
                onChange={(option: DropdownOption) => {
                  setSelectedConnection(option);
                }}
                options={slackConnectionOptions}
                value={selectedConnection}
                isClearable={false}
              />
            </div>
          ))}
        {selectedConnection && (
          <div>
            <FieldLabel>Slack channel</FieldLabel>
            <Select
              placeholder="Select a channel"
              onChange={(option: DropdownOption) => {
                setSelectedChannel(option);
              }}
              options={slackChannelOptions}
              value={selectedChannel}
              isClearable={false}
              isLoading={isSlackChannelsLoading}
            />
          </div>
        )}
      </div>
      <div className="flex shrink-0 grow-0 flex-row-reverse gap-4 py-4">
        <ActionButton
          isDisabled={
            isSlackChannelsLoading || !selectedChannel || !selectedConnection
          }
          onClick={() => {
            if (selectedChannel && selectedConnection) {
              props.onSaveChannel(selectedChannel, selectedConnection);
            }
          }}
          isLoading={props.isLoading}
        >
          Save
        </ActionButton>
      </div>
    </>
  );
}
