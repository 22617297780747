import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";

import { useReverseEtlJobStatusQuery } from "@/apollo/types";
import Badge, { BadgeVariant } from "@/components/elements/Badge_Legacy";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatTime } from "@/components/elements/FormatTime";
import IntegrationCellSyncFrequency from "@/components/elements/IntegrationCellSyncFrequency";
import Tooltip from "@/components/elements/Tooltip";
import { TextMuted } from "@/components/elements/Typography";
import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";
import { useInterval } from "@/hooks/useInterval";
import { useTimeout } from "@/hooks/useTimeout";
import useUpdate from "@/hooks/useUpdate";

import { RowData } from "../columns";

const getStatus = (job: RowData["job"]) => {
  if (!job || job.status !== "STARTED") {
    return {
      isRunning: false,
      isStarting: false,
    };
  }
  const isRunning = job.running;
  let isStarting = false;
  if (job.nextRun) {
    const nextRun = dayjs(job.nextRun);
    isStarting = dayjs().isAfter(nextRun);
  }
  return {
    isRunning,
    isStarting,
  };
};

const isRunningOrStarting = (job: RowData["job"]) => {
  if (!job || job.status !== "STARTED") return false;
  const jobStatus = getStatus(job);
  return jobStatus.isRunning || jobStatus.isStarting;
};

const createPollInterval = () => {
  const baseInterval = 30_000; // 30 seconds
  const jitter = Math.random() * 5000; // Add up to 5s random jitter
  return baseInterval + jitter;
};

function useQueueOnNextRunExceeded(job: RowData["job"]) {
  const getMillisecondsUntilNextRun = () => {
    if (!job?.nextRun) return null;
    const now = Date.now();
    if (job.nextRun < now) return null;
    return job.nextRun - now;
  };

  const millisecondsUntilNextRun = getMillisecondsUntilNextRun();
  useTimeout(useUpdate(), millisecondsUntilNextRun);
}

function useNextSyncStatus(syncId: string, job: RowData["job"]) {
  const [shouldStartPolling, setShouldStartPolling] = useState(false);
  const pollIntervalRef = useRef<number | undefined>(createPollInterval());

  useEffect(() => {
    // Block polling initially since we already have the job status via props on mount.
    const timeout = setTimeout(() => {
      setShouldStartPolling(true); // Enable polling after delay
    }, pollIntervalRef.current);
    return () => clearTimeout(timeout);
  }, []);

  const { data } = useReverseEtlJobStatusQuery({
    fetchPolicy: "no-cache",
    variables: { syncId },
    pollInterval: pollIntervalRef.current,
    skipPollAttempt() {
      return document.hidden;
    },
    skip: !shouldStartPolling || !isRunningOrStarting(job),
  });

  // This will trigger a rerender when `nextRun` is reached which in turn
  // will start the polling again
  useQueueOnNextRunExceeded(job);

  if (data?.findOneSync.job) {
    return getStatus(data.findOneSync.job);
  }
  return getStatus(job);
}

export function NextSyncCell({
  syncInterval,
  row,
}: {
  syncInterval?: string | null;
  row: RowData;
}) {
  useInterval(() => {}, 5000);

  const { isRunning, isStarting } = useNextSyncStatus(row.id, row.job);

  if (isRunning) {
    return <Badge variant={BadgeVariant.Default}>Running</Badge>;
  }
  if (isStarting) {
    return <Badge variant={BadgeVariant.Inactive}>Queued</Badge>;
  }

  if (row.status === "NOT_STARTED" || row.status === "PAUSED") {
    return <TextMuted>-</TextMuted>;
  }

  const cronExpression = row.orchestrationWorkflow?.id
    ? row.orchestrationWorkflow.cronExpression
    : syncInterval;

  if (cronExpression) {
    const date = cronExpressionToDayjs(cronExpression);
    return (
      <>
        <Tooltip content={<FormatTime date={date} format="LLL" />}>
          <FormatRelativeTime date={date} className="mb-1 block" />
        </Tooltip>
        <TextMuted
          as="div"
          className="hidden whitespace-nowrap text-xs leading-none md:block"
        >
          <CronDisplayReadable cron={cronExpression} />
        </TextMuted>
      </>
    );
  }
  if (row.orchestrationWorkflow?.id)
    return (
      <IntegrationCellSyncFrequency
        syncInterval={row.orchestrationWorkflow.cronExpression}
      />
    );
  if (syncInterval) {
    return <IntegrationCellSyncFrequency syncInterval={syncInterval} />;
  }
  return null;
}
