import React from "react";

import { SecondaryButton } from "@/components/elements/Button";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { useNavigateToWorkspace } from "@/router";
import { useNavigate } from "@tanstack/react-location";

import PageContainer from "../components/PageContainer";
import { useConnectCustomDW } from "../hooks/useConnectCustomDW";
import { ConnectContext } from "./ConnectContext";

export default function ConnectDataWarehouseProvider(props: {
  children: React.ReactNode;
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const navigateToWorkspace = useNavigateToWorkspace();
  const account = useCurrentAccount();

  const dataWarehouseConnectIdRef = React.useRef(
    account.dataWarehouseConnectionId,
  );

  const [connectDataWarehouse, { isConnecting }] = useConnectCustomDW({
    onCompleted(account) {
      // Setup complete
      navigate({
        to: `/setup/complete`,
        search: {
          accountId: account.id,
          slug: account.slug,
        },
      });
    },
    onError(error) {
      toast(
        error.message,
        "The connection to the data warehouse failed. Please try again.",
        "error",
      );
    },
  });

  const connect: React.ContextType<typeof ConnectContext>["connect"] =
    React.useCallback(
      async (integrationId, configuration) => {
        // Connect data warehouse
        return connectDataWarehouse(account, integrationId, configuration);
      },
      [connectDataWarehouse, account],
    );

  if (dataWarehouseConnectIdRef.current != null) {
    return (
      <PageContainer>
        <span>
          A data warehouse has already been set up for{" "}
          <strong>{account.name}</strong>
        </span>
        <SecondaryButton
          variant="solid"
          onClick={() => navigateToWorkspace.bySlug(account.slug)}
          className="mt-8"
        >
          Go to workspace
        </SecondaryButton>
      </PageContainer>
    );
  }

  return (
    <ConnectContext.Provider value={{ connect, isConnecting }}>
      {props.children}
    </ConnectContext.Provider>
  );
}
