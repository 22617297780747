import React, { useState } from "react";

import {
  CurrentUserWithAccountsDocument,
  useCreateAccountMutation,
} from "@/apollo/types";
import AppearTransition from "@/components/elements/AppearTransition";
import Center from "@/components/elements/layout/Center";
import { Overlay } from "@/components/elements/layout/Overlay";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import * as Sentry from "@sentry/react";
import { useNavigate } from "@tanstack/react-location";

import LoadingScreenQuestionnaire from "../components/LoadingScreenQuestionnaire";
import { useConnectCustomDW } from "../hooks/useConnectCustomDW";
import { useWorkspaceCreationContext } from "../hooks/useWorkspaceCreationContext";
import { ConnectContext } from "./ConnectContext";
import { useCreateWorkspaceContext } from "./CreateWorkspaceContext";

export function CustomDataWarehouseProvider(props: {
  children: React.ReactNode;
}) {
  const toast = useToast();
  const navigate = useNavigate();
  const mixpanel = useMixpanel();

  const [isLoadingQuestionnaireOngoing, setLoadingQuestionnaireOngoing] =
    useState(false);

  const createWorkspaceContext = useCreateWorkspaceContext();
  const creationContext = React.useRef(useWorkspaceCreationContext()).current;

  React.useEffect(() => {
    if (!creationContext?.name) {
      // Redirect back to "Set up workspace" page if no creation context is available
      navigate({ to: "..", replace: true });
    }
  }, [creationContext?.name, navigate]);

  const [createAccount, createAccountResult] = useCreateAccountMutation({
    onCompleted(data) {
      createWorkspaceContext.onAccountCreated(data.createAccount);
    },
    onError(error) {
      toast("Aww, something went wrong", error.message, "error");
      Sentry.captureException(error);
    },
    refetchQueries: [CurrentUserWithAccountsDocument],
    awaitRefetchQueries: true,
  });

  const navigateOnSetupComplete = () => {
    const account = createAccountResult.data?.createAccount;
    navigate({
      to: "/setup/complete",
      search: {
        accountId: account?.id,
        slug: account?.slug,
      },
    });
  };

  const [connectDataWarehouse, { isConnected, isConnecting }] =
    useConnectCustomDW({
      onCompleted() {
        if (!isLoadingQuestionnaireOngoing) {
          navigateOnSetupComplete();
        }
      },
      onError(error, account, integrationId) {
        mixpanel.track("Connect Custom DW Failed", {
          integration_id: integrationId,
          original_integration_id: creationContext?.integrationId,
          error_message: error.message,
          new_workspace_id: account.id,
          new_workspace_slug: account.slug,
        });
        // An account was created but the data warehouse connection failed
        // Redirect to the "Connect data warehouse" page to re-attempt connection
        toast(
          error.message,
          "Your account was created but the connection to the data warehouse failed. Please try again.",
          "error",
        );
        navigate({
          to: `/workspaces/${account.slug}/connect-dw`,
          search: {
            integrationId: creationContext?.integrationId ?? integrationId,
          },
        });
      },
    });

  const createAndConnect: React.ContextType<typeof ConnectContext>["connect"] =
    React.useCallback(
      async (integrationId, configuration) => {
        if (creationContext == null || !creationContext.name) {
          throw new Error("No creation context");
        }
        // Create account
        const result = await createAccount({
          variables: {
            name: creationContext.name,
            isTest: creationContext.isTest || false,
          },
        });
        if (!result.data) {
          throw new Error(
            'Account creation failed. "result.data" is undefined',
          );
        }
        // Connect data warehouse
        return connectDataWarehouse(
          result.data.createAccount,
          integrationId,
          configuration,
        );
      },
      [creationContext, createAccount, connectDataWarehouse],
    );

  // Once `connectDatawarehouse` is called we show the loading screen.
  // Only if the API call fails we show the form again
  const showWeldBQLoadingScreen = isConnecting || isLoadingQuestionnaireOngoing;
  return (
    <>
      <ConnectContext.Provider
        value={{ isConnecting, connect: createAndConnect }}
        {...props}
      />
      {showWeldBQLoadingScreen && (
        <AppearTransition>
          <Overlay>
            <Center className="h-full bg-white dark:bg-gray-800">
              <LoadingScreenQuestionnaire
                isLoadingComplete={isConnected}
                onQuestionnaireStart={() => {
                  setLoadingQuestionnaireOngoing(true);
                }}
                onQuestionnaireComplete={() => {
                  setLoadingQuestionnaireOngoing(false);
                  if (isConnected) {
                    navigateOnSetupComplete();
                  }
                }}
                onQuestionnaireSkipped={() => {
                  setLoadingQuestionnaireOngoing(false);
                  navigateOnSetupComplete();
                }}
              />
            </Center>
          </Overlay>
        </AppearTransition>
      )}
    </>
  );
}
