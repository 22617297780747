import { LinkWithSlug } from "routes";

import {
  FindOneSyncQuery,
  ListModelsQuery,
  ListSyncsQuery,
  Mapping,
} from "@/apollo/types";
import { CronDisplayReadable } from "@/components/elements/CronDisplayReadable";
import { DetailItem } from "@/components/elements/DataBox";
import { FormatRelativeTime } from "@/components/elements/FormatRelativeTime";
import { FormatTime } from "@/components/elements/FormatTime";
import LoadingSpinner from "@/components/elements/LoadingSpinner";
import Tooltip from "@/components/elements/Tooltip";
import cronExpressionToDayjs from "@/helpers/cronExpressionToDayjs";
import { dateTimeFormat } from "@/helpers/dateFormat";
import syncOperationExplainers from "@/helpers/syncOperationExplainers";
import useNow from "@/hooks/useNow";
import { IntegrationLogo } from "@/integrations";
import { ModelIcon } from "@/pages/ModelTool/components/ModelIcon/ModelIcon";
import { useModel } from "@/pages/ModelTool/hooks/useCurrentModel";
import { useFullModelPathRenderer } from "@/pages/ModelTool/hooks/useFullPathNames";
import { useOrchestrationWorkflow } from "@/pages/Orchestration/components/useWorkflow";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

type SyncProps = {
  sync: FindOneSyncQuery["findOneSync"];
};

function SyncModelName({ sync }: SyncProps) {
  const { model, loading } = useModel(sync?.model?.id ?? "");

  return (
    <DetailItem title="Model">
      {loading && <LoadingSpinner />}
      {model && <ModelDisplay model={model} />}
    </DetailItem>
  );
}

const ModelDisplay = ({ model }: { model: ListModelsQuery["models"][0] }) => {
  const modelDisplayName = useFullModelPathRenderer(model);

  return (
    <div className="flex items-center space-x-1">
      <ModelIcon model={model} />
      <LinkWithSlug className="underline" to={`/editor/${model.id}`}>
        {modelDisplayName}
      </LinkWithSlug>
    </div>
  );
};

const IntegrationSpecificFields = (props: {
  rEltSync: ListSyncsQuery["syncs"][0] | FindOneSyncQuery["findOneSync"];
}) => {
  switch (props.rEltSync.destinationIntegrationId) {
    case "google-sheets":
    case "google-sheets-service-account":
      return (
        <>
          <ChevronRightIcon className="h-4 w-4" />
          <span>
            {props.rEltSync.config?.destinationSettings?.spreadsheet?.label} (
            {props.rEltSync.config?.destinationSettings?.sheet?.label})
          </span>
        </>
      );
    default:
      return null;
  }
};

function RevEltDisplayNameSmall(props: {
  rEltSync: FindOneSyncQuery["findOneSync"];
}) {
  return (
    <div className="flex items-center space-x-2">
      <IntegrationLogo
        id={props.rEltSync.destinationIntegrationId}
        className="h-5"
      />
      <div className="flex items-center space-x-1 text-sm text-gray-800 dark:text-white">
        <div>
          {props.rEltSync.destinationConnection.label ||
            props.rEltSync.destinationIntegrationId}
        </div>
        <ChevronRightIcon className="h-3 w-3" />
        <div className="">{props.rEltSync.primaryObject}</div>
        {props.rEltSync.config?.destinationSettings?.scopeId && (
          <>
            <ChevronRightIcon className="h-3 w-3" />
            <div className="">
              {props.rEltSync.config?.destinationSettings?.scopeId}
            </div>
          </>
        )}
        <IntegrationSpecificFields rEltSync={props.rEltSync} />
      </div>
    </div>
  );
}

function SyncConnector({ sync }: SyncProps) {
  return (
    <DetailItem title="Connector">
      <div className="flex items-center space-x-1">
        <LinkWithSlug
          to={`/settings/connections/${sync.destinationConnectionId}`}
        >
          <RevEltDisplayNameSmall rEltSync={sync} />
        </LinkWithSlug>
      </div>
    </DetailItem>
  );
}

function SyncSchedule({ sync }: SyncProps) {
  if (sync.schedulerType === "local") {
    return <SyncLocalScheduler sync={sync} />;
  }
  if (sync.schedulerType === "global") {
    return <OrchestrationDetails sync={sync} />;
  }
  return null;
}

function SyncLocalScheduler({ sync }: SyncProps) {
  return (
    <DetailItem title="Frequency">
      {sync.syncInterval ? (
        <Tooltip
          content={
            <span>
              Next sync:{" "}
              <FormatTime date={cronExpressionToDayjs(sync.syncInterval)} />
            </span>
          }
        >
          <div className="cursor-help">
            <CronDisplayReadable cron={sync.syncInterval} />{" "}
            <span className="text-gray-400">
              (
              <FormatRelativeTime
                date={cronExpressionToDayjs(sync.syncInterval)}
              />
              )
            </span>
          </div>
        </Tooltip>
      ) : (
        "Not defined"
      )}
    </DetailItem>
  );
}

const OrchestrationDetails = (props: {
  sync: FindOneSyncQuery["findOneSync"];
}) => {
  const { workflow, loading } = useOrchestrationWorkflow(
    props.sync.orchestrationWorkflow?.id,
  );
  const now = useNow(10000);
  return (
    <>
      <div className="">
        <DetailItem title="Frequency">
          <div>
            {workflow?.cronExpression ? (
              <Tooltip
                content={`Next run: ${cronExpressionToDayjs(
                  workflow.cronExpression,
                ).format(dateTimeFormat)}`}
              >
                <div className="cursor-help">
                  <CronDisplayReadable cron={workflow.cronExpression} />{" "}
                  <span className="text-gray-400">
                    ({cronExpressionToDayjs(workflow.cronExpression).from(now)})
                  </span>
                </div>
              </Tooltip>
            ) : loading ? (
              <LoadingSpinner />
            ) : (
              "Not defined"
            )}
          </div>
        </DetailItem>
      </div>
      <div className="">
        <DetailItem title="Orchestration workflow">
          {workflow ? (
            <LinkWithSlug
              className="underline"
              to={`/orchestration/${workflow.id}`}
            >
              {workflow.name}
            </LinkWithSlug>
          ) : loading ? (
            <LoadingSpinner />
          ) : (
            "Not defined"
          )}
        </DetailItem>
      </div>
    </>
  );
};

function SyncOperation({ sync }: SyncProps) {
  const operation = sync?.operations?.[0];
  return (
    <DetailItem title="Operation">
      <Tooltip
        content={syncOperationExplainers(operation?.operationMode ?? "")}
      >
        <span className="capitalize">{operation?.operationMode}</span>
      </Tooltip>
    </DetailItem>
  );
}

function SyncMappings({ sync }: SyncProps) {
  const operation = sync?.operations?.[0];
  return (
    <>
      {operation?.primaryKeyRequired && (
        <DetailItem title="Identifiers" multi>
          <MappingItem
            from={getPrimaryKey(sync.mappings)?.sourcePropertyName}
            to={getPrimaryKey(sync.mappings)?.destinationPropertyName}
          />
        </DetailItem>
      )}
      <DetailItem title="Mapping" multi>
        <div className="space-y-2">
          {sync.mappings.map((item) => {
            return (
              <MappingItem
                key={item.id}
                from={item.sourcePropertyName}
                to={item.destinationPropertyName}
              />
            );
          })}
        </div>
      </DetailItem>
    </>
  );
}

const SyncDetails = {
  ModelName: SyncModelName,
  Connector: SyncConnector,
  Schedule: SyncSchedule,
  Operation: SyncOperation,
  Mappings: SyncMappings,
};

export default SyncDetails;

const getPrimaryKey = (
  mappings: Array<
    { __typename?: "Mapping" } & Pick<
      Mapping,
      | "id"
      | "sourcePropertyId"
      | "sourcePropertyName"
      | "sourcePropertyType"
      | "destinationPropertyId"
      | "destinationPropertyName"
      | "destinationPropertyType"
      | "readonly"
      | "primaryKey"
    >
  >,
) => {
  return mappings.find((mapping: any) => {
    return mapping.primaryKey;
  });
};

const MappingItem = ({
  from,
  to,
}: {
  from: string | undefined;
  to: string | undefined;
}) => (
  <div className="flex">
    <div className="flex min-w-0 items-center space-x-2 rounded bg-gray-100 p-3 text-xs text-gray-700 dark:bg-gray-700 dark:text-white">
      <Tooltip content={from || ""}>
        <span className="truncate" style={{ minWidth: "10px" }}>
          {from}
        </span>
      </Tooltip>
      <ChevronRightIcon className="h-3 text-gray-500 dark:text-white" />
      <Tooltip content={to || ""}>
        <span className="truncate" style={{ minWidth: "10px" }}>
          {to}
        </span>
      </Tooltip>
    </div>
  </div>
);
