import { useDestinationPropertiesQuery } from "@/apollo/types";
import { keyBy } from "lodash";
import { useCallback, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { useSyncContext } from "../modules/SyncContext";

export default function useDestinationProperties() {
  const [state, dispatch] = useSyncContext();
  const {
    data: { destinationProperties = [] } = {},
    refetch,
    loading: destinationPropertiesLoading,
  } = useDestinationPropertiesQuery({
    variables: {
      destinationConnectionId: state.destinationId,
      objectType: state.objectTypeName,
      operationMode: state.operation?.mode ?? "",
      modelId: state.queryId,
    },
    skip:
      !state.destinationId ||
      !state.objectTypeName ||
      !state.operation ||
      !state.queryId,
    onCompleted(data) {
      const requiredDestinationProperties = data.destinationProperties.filter(
        (dp) => dp.isRequired,
      );

      const object = keyBy(data.destinationProperties, "propertyId");

      dispatch({
        type: "initialize_mapping",
        payload: [
          ...requiredDestinationProperties.map((destinationProperty) => {
            const existingMapping = state.mapping.find(
              (m) => m.destinationPropertyId === destinationProperty.propertyId,
            );
            return {
              sourcePropertyId: undefined,
              uuid: uuid(),
              ...existingMapping,
              destinationPropertyId: destinationProperty.propertyId,
            };
          }),
          ...state.mapping.filter((item) => {
            const destinationProperyIsRequired =
              item.destinationPropertyId &&
              object[item.destinationPropertyId]?.isRequired;

            return (
              !destinationProperyIsRequired &&
              !requiredDestinationProperties.some(
                (dp) => dp.propertyId === item.destinationPropertyId,
              )
            );
          }),
        ],
      });
    },
  });

  const destinationPropertiesObject = useMemo(
    () => keyBy(destinationProperties, "propertyId"),
    [destinationProperties],
  );

  const refetchDestinationPropertiesWithVariables = useCallback(() => {
    if (!state.destinationId || !state.objectTypeName || !state.queryId) return;
    refetch();
  }, [refetch, state.destinationId, state.objectTypeName, state.queryId]);

  return {
    refetchDestinationProperties: refetchDestinationPropertiesWithVariables,
    destinationProperties,
    destinationPropertiesObject,
    destinationPropertiesLoading,
  };
}
