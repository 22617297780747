import { isEmpty } from "lodash";
import { LocationGenerics, retryRoute } from "routes";

import {
  useOpenInvitationsLazyQuery,
  useUserSignupInfoLazyQuery,
} from "@/apollo/types";
import { useMountEffect } from "@/hooks/useMountEffect";
import { DestinationsIntegrationsProvider } from "@/integrations";
import { useCurrentUser, useUserAccounts } from "@/providers/UserProvider";
import {
  Outlet,
  Route,
  RouteLoaders,
  useNavigate,
} from "@tanstack/react-location";

import { CustomDataWarehouseProvider } from "./contexts/CustomDataWarehouseProvider";
import { ManagedDataWarehouseProvider } from "./contexts/ManagedDataWarehouseProvider";

/**
 * Helper function to load a specific setup page and at the same time load all other setup pages.
 * Rationale: the setup flow is step-based so the individual pages are very likely to be visited one after another and
 * therefore we don't want to load each page as a separate chunk, i.e. better bundle all setup pages together.
 * @param callback with import result as argument to select route loaders for a specific route
 * @returns function to be used as `import` on a specific route
 */
export const loadRoute = (
  callback: (
    res: typeof import("pages/Setup/routes.loadable"),
  ) => RouteLoaders<LocationGenerics>,
): (() => Promise<RouteLoaders<LocationGenerics>>) => {
  const importFn = () => import("pages/Setup/routes.loadable");
  return () => {
    return new Promise(async (resolve) => {
      try {
        const module = await retryRoute(importFn);
        if (module) {
          const result = callback(module);
          resolve(result);
        }
      } catch {
        // just swallow this
      }
    });
  };
};

const routes: Route<LocationGenerics>[] = [
  {
    path: "setup",
    import: loadRoute((res) => res.default.OnboardingContainer),
    children: [
      {
        path: "user",
        import: loadRoute((res) => res.default.SetupUserPage),
      },
      generateCreateWorkspaceRoutes("create-workspace"),
      // {
      //   path: "connectors",
      //   import: loadRoute((res) => res.default.SetupConnectors),
      // },
      {
        path: "complete",
        import: loadRoute((res) => res.default.SetupCompletePage),
      },
      {
        element: <SetupRouter />,
      },
    ],
  },
];

export default routes;

export function generateCreateWorkspaceRoutes(
  basePath?: string,
): Route<LocationGenerics> {
  return {
    path: basePath,
    element: (
      <DestinationsIntegrationsProvider>
        <ManagedDataWarehouseProvider>
          <Outlet />
        </ManagedDataWarehouseProvider>
      </DestinationsIntegrationsProvider>
    ),
    children: [
      {
        path: "advanced",
        element: (
          <CustomDataWarehouseProvider>
            <Outlet />
          </CustomDataWarehouseProvider>
        ),
        children: [
          {
            id: "configure-dw",
            search: (search) => {
              if ((search as any).creationContext?.integrationId) {
                return (
                  (search as any).creationContext.integrationId !== undefined
                );
              }
              return search.integrationId !== undefined;
            },
            import: loadRoute((res) => res.default.ConfigureDataWarehousePage),
          },
        ],
      },
      {
        import: loadRoute((res) => res.default.CreateAccountPage),
      },
    ],
  };
}

/**
 * This is the default setup route, i.e. /setup
 * Figure out where to redirect the user:
 *
 * 1. is the user a new lead i.e. new sign up without an invitation?
 * Then redirect to the set up user page which will create a lead in HubSpot
 *
 * 2. User is already a member of a workspace or has an invitation to a workspace?
 * Then redirect to the workspace overview page.
 */
function SetupRouter() {
  const navigate = useNavigate();

  const user = useCurrentUser();
  const accounts = useUserAccounts();

  const [getOpenInvitations] = useOpenInvitationsLazyQuery();
  const [getSignupInfo] = useUserSignupInfoLazyQuery();

  useMountEffect(async () => {
    const invitationsResp = await getOpenInvitations();
    const invitations = invitationsResp.data?.openInvitations ?? [];

    const hasWorkspaceMembership = !isEmpty(accounts);
    const hasWorkspaceInvitations = !isEmpty(invitations);

    if (!hasWorkspaceMembership && !hasWorkspaceInvitations) {
      const userWithSignupInfoResp = await getSignupInfo({
        variables: {
          userId: user.id,
        },
      });
      if (userWithSignupInfoResp.data?.user.userSignupInfo == null) {
        navigate({ to: "/setup/user", replace: true, search: true });
        return;
      } else {
        const { companyName } = userWithSignupInfoResp.data.user.userSignupInfo;
        navigate({
          to: "/setup/create-workspace",
          replace: true,
          search: (old) => ({
            ...old,
            companyName,
          }),
        });
        return;
      }
    } else {
      navigate({ to: "/workspaces", replace: true, search: true });
    }
  });

  return <div />;
}
