import dayjs from "dayjs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useState } from "react";

import {
  ConnectionDocument,
  FindOneSyncDocument,
  ListSyncsDocument,
  PlanName,
  RemoveSyncMutationVariables,
  SubscriptionStatus,
  useRemoveSyncMutation,
  useRunRevEtlSyncNowMutation,
  useStartSyncMutation,
  useStopSyncMutation,
} from "@/apollo/types";
import ConfirmDeleteModal from "@/components/elements/ConfirmDeleteModal";
import MenuItem from "@/components/elements/MenuItem";
import { PlanFeatureBadge } from "@/components/elements/PlanFeatureBadge";
import Tooltip from "@/components/elements/Tooltip";
import TableMenu from "@/components/modules/TableMenu";
import { useCurrentPlan, useSubscriptionStatus } from "@/features/subscription";
import cn from "@/helpers/classNames";
import useDeleteItem from "@/hooks/useDeleteItem";
import usePrevious from "@/hooks/usePrevious";
import { useToast } from "@/providers/ToastProvider";
import { Menu } from "@headlessui/react";

import { RowData } from "../columns";

function useRunSyncNow(sync: RowData) {
  const toast = useToast();
  const [runSyncNow, { loading }] = useRunRevEtlSyncNowMutation({
    refetchQueries: [
      {
        query: FindOneSyncDocument,
        variables: { syncId: sync.id },
      },
    ],
    variables: {
      syncId: sync.id,
    },
    onError(error) {
      toast("Cannot run sync now", error.message, "error");
    },
  });

  const canTriggerSync = () => {
    const job = sync.job;
    if (!job?.nextRun) {
      return false;
    }
    const nextRun = dayjs(job.nextRun);
    const jobIsRunning = job.running;
    const jobIsStarting = dayjs().isAfter(nextRun);
    const isQuarantined = job.isQuarantined;
    return !jobIsRunning && (!jobIsStarting || isQuarantined);
  };

  return {
    runSyncNow: () => {
      if (!loading && canTriggerSync()) {
        runSyncNow();
      }
    },
    isLoading: loading,
    isDisabled: !canTriggerSync(),
  };
}

export const SyncMenuCell = ({ sync }: { sync: RowData }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDelete = useDeleteItem<RemoveSyncMutationVariables>({
    title: "sync",
    variables: { syncId: sync.id },
    mutation: useRemoveSyncMutation,
    refetchQueries: [
      { query: ListSyncsDocument },
      {
        query: ConnectionDocument,
        variables: { id: sync.destinationConnection?.id ?? "" },
      },
    ],
  });

  const runSyncNow = useRunSyncNow(sync);
  return (
    <>
      <ConfirmDeleteModal
        title="sync"
        onConfirm={handleDelete}
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
      />
      <TableMenu>
        <div className="divide-y">
          <div className="py-1">
            <ToggleSyncMenuItem syncId={sync.id} active={sync.active} />
            {sync.active && <RunSyncNowMenuItem {...runSyncNow} />}
          </div>
          <div className="py-1">
            <MenuItem
              danger={true}
              text="Delete"
              onClick={() => setShowConfirmModal(true)}
            />
          </div>
        </div>
      </TableMenu>
    </>
  );
};

const ToggleSyncMenuItem = ({
  syncId,
  active,
}: {
  syncId: string;
  active: boolean;
}) => {
  const toast = useToast();

  const prevActive = usePrevious(active);

  const useMutation = active ? useStopSyncMutation : useStartSyncMutation;
  const [toggleSync] = useMutation({
    onError(error) {
      toast(
        `Sync not ${active ? "stopped" : "started"}`,
        error.message,
        "error",
      );
    },
    onCompleted() {
      toast(
        `Sync ${prevActive ? "stopped" : "started"}`,
        `Your sync has been succesfully ${prevActive ? "stopped" : "started"}.`,
        "success",
      );
    },
    refetchQueries: [{ query: ListSyncsDocument, variables: { syncId } }],
  });

  const onToggleSync = (e: any) => {
    e.stopPropagation();
    toggleSync({
      variables: { syncId },
    });
  };

  return <MenuItem text={active ? "Stop" : "Start"} onClick={onToggleSync} />;
};

function RunSyncNowMenuItem(props: ReturnType<typeof useRunSyncNow>) {
  const { status } = useSubscriptionStatus();
  const { features } = useCurrentPlan();
  const flags = useFlags();

  const onDemandSyncsEnabled =
    features.onDemandSyncsEnabled || flags.onDemandSyncsEnabledForUser;
  const isDisabled = props.isDisabled || onDemandSyncsEnabled !== true;

  return (
    <Menu.Item disabled={isDisabled}>
      {({ active, close }) => (
        <div>
          <Tooltip
            content={
              !onDemandSyncsEnabled
                ? "On-demand sync triggering is available with the Premium plan"
                : null
            }
          >
            <span>
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  props.runSyncNow();
                  close();
                }}
                disabled={isDisabled}
                className={cn(
                  "pointer-events-auto flex w-full px-4 py-2 text-left text-sm dark:border-gray-600",
                  {
                    "bg-gray-100 dark:bg-gray-700": active,
                    "text-foreground/40": isDisabled,
                  },
                )}
              >
                <span>Run sync now</span>
                {status === SubscriptionStatus.Freemium ||
                  (!onDemandSyncsEnabled && (
                    <PlanFeatureBadge
                      planName={PlanName.Premium}
                      className="ml-auto"
                    />
                  ))}
              </button>
            </span>
          </Tooltip>
        </div>
      )}
    </Menu.Item>
  );
}
