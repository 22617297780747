import { FindOneSyncQuery, ListSyncsQuery } from "@/apollo/types";
import { IntegrationLogoBox } from "@/integrations";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

export function ConnectorCell(props: { rEltSync: ListSyncsQuery["syncs"][0] }) {
  return (
    <div className="flex items-center space-x-4">
      <IntegrationLogoBox
        id={props.rEltSync.destinationIntegrationId}
        size="sm"
      />
      <div className="flex flex-col">
        <div className="mb-1 flex items-center space-x-1 whitespace-nowrap font-medium text-gray-800 dark:text-white">
          <div>{props.rEltSync.primaryObject}</div>
          {props.rEltSync.config?.destinationSettings?.scopeId && (
            <>
              <ChevronRightIcon className="h-4 w-4" />
              <div>{props.rEltSync.config?.destinationSettings?.scopeId}</div>
            </>
          )}
          <IntegrationSpecificFields rEltSync={props.rEltSync} />
        </div>

        <div className="flex items-center space-x-2 text-xs leading-none text-gray-500">
          {props.rEltSync.destinationConnection.label ||
            props.rEltSync.destinationIntegrationId}
        </div>
      </div>
    </div>
  );
}

export function RevEltDisplayNameSmall(props: {
  rEltSync: FindOneSyncQuery["findOneSync"];
}) {
  return (
    <div className="flex items-center space-x-2">
      <IntegrationLogoBox
        id={props.rEltSync.destinationIntegrationId}
        size={"1xs"}
      />
      <div className="flex items-center space-x-1 text-sm text-gray-800 dark:text-white">
        <div>
          {props.rEltSync.destinationConnection.label ||
            props.rEltSync.destinationIntegrationId}
        </div>
        <ChevronRightIcon className="h-3 w-3" />
        <div className="">{props.rEltSync.primaryObject}</div>
        {props.rEltSync.config?.destinationSettings?.scopeId && (
          <>
            <ChevronRightIcon className="h-3 w-3" />
            <div className="">
              {props.rEltSync.config?.destinationSettings?.scopeId}
            </div>
          </>
        )}
        <IntegrationSpecificFields rEltSync={props.rEltSync} />
      </div>
    </div>
  );
}

const IntegrationSpecificFields = (props: {
  rEltSync: ListSyncsQuery["syncs"][0] | FindOneSyncQuery["findOneSync"];
}) => {
  switch (props.rEltSync.destinationIntegrationId) {
    case "google-sheets":
    case "google-sheets-service-account":
      return (
        <>
          <ChevronRightIcon className="h-4 w-4" />
          <span>
            {props.rEltSync.config?.destinationSettings?.spreadsheet?.label} (
            {props.rEltSync.config?.destinationSettings?.sheet?.label})
          </span>
        </>
      );
    default:
      return null;
  }
};
